import './form-select.scss'

const FormSelect = ({ label, options, defaultValue = 'Choose your option', ...otherProps }) => {
    return (
        <div className='group'>
            <select className='form-select' {...otherProps}>
                <option value="" disabled defaultValue>{defaultValue}</option>
                {options.map((option) => (
                    <option key={option}>{option}</option>
                ))}
            </select>
            {label && (
                <label className="shrink form-select-label">{label}</label>
            )}
        </div>
    );
}

export default FormSelect;