import './sign-in-form.scss'
import { signInWithGooglePopup, signInWithGoogleRedirect, auth, signInUserWithEmailAndPassword } from '../../../utils/firebase';
import { getRedirectResult } from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import FormInput from '../../../components/form-input/form-input';
import Button from '../../../components/button/button';
import {useNavigate} from 'react-router-dom';
import android from '../../../assets/android.png'
import { downloadFile } from '../../../utils/firebase';
import { UserContext } from '../../../context/user-context';
import { getUser } from '../../../utils/firebase';

const defaultFormFields = {
    email: '',
    password: '',
}

const SignInForm = () => {
    const { currentUser, setCode } = useContext(UserContext);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    
    // Google redirect login
    useEffect(() => {
        const getResponse = async () => {
            await getRedirectResult(auth);
        }
        getResponse();
    }, []);

    // Google popup login
    const logGoogleUser = async () => {
        await signInWithGooglePopup();
    }

    
    // Email and Password login
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { email, password} = formFields;

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({...formFields, [name]: value});
    }

    function sendmail(email, id) {
        window.Email.send({
            SecureToken : '51fc8dd8-da31-4c70-b4a1-cc816563fb8e',
            To : email,
            From : 'pgcn.dts@gmail.com',
            Subject : 'PGCN Document Tracking System',
            Body : '<html>Your two-factor authentication code is:<br/><br/><b>' + id + '</b></html>'
        });
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const { user } = await signInUserWithEmailAndPassword(email, password);
            const data = await getUser(user.uid);
            resetFormFields();
            
            if (data.two_factor) {
                const c = (Math.random() + 1).toString(36).substring(7);
                sendmail(email, c);
                setCode(c);
            }
            
            navigate('/');
        } catch (error) {
            switch(error.code) {
                case "auth/wrong-password":
                    alert("Incorrect password");
                    break;
                case "auth/user-not-found":
                    alert("User not found");
                    break;
                default:
                    alert(error.message);
            }
        }
    }

    const onCLickHandler = () => {
        const downloadUrl = "https://firebasestorage.googleapis.com/v0/b/document-tracking-system-3cf01.appspot.com/o/dts-v1.10.apk?alt=media&token=735b1850-b6f8-4f1b-b8d4-4d92602d5e45";
        const fileName = "DTS_v1.10.apk";
        const res = downloadFile(fileName, downloadUrl);
    }

    return (
        <div className='main-container'>
            <div className='download-container'>
                <img src={android} alt="image"/>
                <Button type="submit" onClick={onCLickHandler}>Download APK</Button>
            </div>
            
            <div className='login-container'>
                <h2>Document Tracking System</h2>
                <p>Sign in with your email and password</p>
                <form onSubmit={handleSubmit}>
                    <FormInput type="email" required name="email" value={email} onChange={handleChange} label="Email Address"/>

                    <FormInput type={show ? "text": "password"} required name="password" value={password} onChange={handleChange} label="Password"/> 

                    <div className='group'>
                        <input type="checkbox" checked={show} name="checkbox" onChange={() => setShow(!show)}/>
                        <label onClick={() => setShow(!show)}>Show Password</label>
                    </div>
                    
                    <div className='buttons-container'>
                        <Button type="submit">Sign in</Button>
                        {/* <Button type='button' onClick={signInWithGoogleRedirect} buttonType="google">Google Sign in</Button> */}
                        {/* <button type='button' onClick={logGoogleUser}>Sign in with Google</button> */}
                    </div>
                    <br/>
                    <br/>
                    <span className='is-clickable' onClick={() => {navigate("/forgot-password")}}>Forgot Password?</span>
                </form>
            </div>
        </div>
    )
}

export default SignInForm;