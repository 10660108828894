import './qr-code.scss'
import {useNavigate, useParams} from 'react-router-dom';
import QRCode from 'react-qr-code';
import Button from '../../components/button/button';
import { format } from "date-fns";
import { completeAda, createTrackingRemarks, createTrackingStatus, deleteTrackingStatus, getTrackingDocument, getTrackingRemarks, getTrackingStatuses, receiveAda, removeAda, updateTracking, updateTrackingRemarks } from '../../utils/firebase';
import { useEffect, useState, useContext, Fragment } from 'react';
import StatusItem from '../../components/status-item/status-item';
import { ProductsContext } from '../../context/product-context';
import html2canvas from 'html2canvas';
import seal from '../../assets/seal.png'
import pagasa from '../../assets/pagasa.png'
import { UsersContext} from '../../context/users-context';
import { UserContext } from "../../context/user-context";
import RoutingSlip from '../routing-slip/routing-slip';
import FormTextArea from '../../components/form-textarea/form-textarea';
import RemarksItem from '../../components/remarks-item/remarks-item';
import { arrayUnion } from 'firebase/firestore';

export const notTracking = [
  "BAC Resolution",
  "Budget",
  "COA",
  "Compliance to Offices",
  "Contract/Plantilla/NOSA",
  "Duty Schedule",
  "Financial Statements",
  "Internal Audit Service",
  "Notice of Salary Adjustment/Notice of Salary Increment",
  "Property Acknowledgement Report/Inventory Custodian Slip",
  "SP Resolution",
  "Summary of Leave Balances",
  "Memorandum/Notices/Order/Executive Order",
  "Invitation for Seminars and Trainings",
  "Statement of Account",
  "Audit Observation Memorandum",
  "Notice of Suspension",
  "Notice of Disallowance",
  "Notice of Settlement of Suspension/Disallowance/Charge",
  "Statement of Appropriation, Allotment, Utilization and Balances",
  "Statement of Assets, Liabilities and Net Worth",
  "Personal Data Sheet",
  "Learning Action Plan/DPCN/Performance Monitoring Sheet",
  "Learning Action Plan",
  "Income Tax Return",
  "Endorsement/ Referrals",
  "MOA/MOU",
  "Transmittal Letter",
  "Compliance to Memo/ Order/ Request",
  "Internal Audit Observation Memorandum",
  "Internal Audit Report",
  "Copy Furnished",
];

const completeTracking = [
  "Travel Order",
  "Purchase Request",
  "Leave",
  "Driver's Trip Ticket/Requisition and Issuance Slip",
  "Accomplishment Reports",
  "Locator Slip",
  "Daily Time Record"
]

const defaultDoc = {
  oid: "",
  completed: false,
  created: new Date(),
  type: "",
  sub: "",
  office: "",
  deleted: false,
  title: "",
  particulars: "",
  pr: "",
  participants: null,
  ada : "",
  user: "",
  selected: [],
  receives: [],
  offices: []
}

const QrCode = () => {
  const [statuses, setStatuses = () => []] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [doc, setDoc] = useState(defaultDoc);
  const {oid, completed, created, type, sub, office, deleted, title, particulars, pr, participants, ada, selected, user} = doc;
  const { users } = useContext(UsersContext);
  const { currentUser } = useContext(UserContext);
  const [remarks, setRemarks] = useState("");
  const [submit, setSubmit] = useState(false);
  const [allRemarks, setAllRemarks = () => []] = useState([]);

  const { offices } = useContext(ProductsContext);
  const pos = offices.map(e => e.id).indexOf(oid);
  const ind = offices.map(e => e.id).indexOf(currentUser.data.oid);
  const isAdmin = currentUser.data.type === "admin";

  useEffect(() => {
      const getResponse = async () => {
        const stats = await getTrackingRemarks(id);

        if (stats) {
          setAllRemarks(stats);
        }
      }
      getResponse();
  }, []);
  
  useEffect(() => {
      const getResponse = async () => {
        const stats = await getTrackingStatuses(id);

        if (stats) {
          setStatuses(stats);
        }
      }
      getResponse();
  }, []);
  
  useEffect(() => {
    const getResponse = async () => {
      const tracking = await getTrackingDocument(id);

      if (tracking) {
        setDoc(tracking);
      }
    }
    getResponse();
}, []);

  const onPrintHandler = () => {
    var printContents = document.getElementById("print");

    html2canvas(printContents, {scale: 3}).then(function(canvas) {
      var myImage = canvas.toDataURL('image/png');

      var mywindow = window.open('PRINT');

      mywindow.document.write('<html><head><title>' + document.title  + '</title>');
      mywindow.document.write('</head><body style="margin: 0 0 0 0">');
      mywindow.document.write('<img src="' + myImage + '" style="width: 90%; display: block; margin: 0 auto"/>');
      mywindow.document.write('</body></html>');
  
      setTimeout(() => {
        mywindow.document.close();
        mywindow.focus();
    
        mywindow.print();
        mywindow.close();
      }, 10)
    })
  }

  const onPrintPageHandler = () => {
    var printContents = document.getElementById("page");
    var remarksField = document.getElementById("remarks-field");
    var printPage = document.getElementById("print-page");
    var buttons = document.getElementById("buttons");
    if (remarksField) {
      remarksField.style.display = "none";
    }
    printPage.style.display = "none";
    buttons.style.display = "none";

    html2canvas(printContents, {scale: 3}).then(function(canvas) {
      var myImage = canvas.toDataURL('image/png');

      var mywindow = window.open('PRINT');

      mywindow.document.write('<html><head><title>' + document.title  + '</title>');
      mywindow.document.write('</head><body style="margin: 0 0 0 0">');
      mywindow.document.write('<img src="' + myImage + '" style="width: 90%; display: block; margin: 0 auto"/>');
      mywindow.document.write('</body></html>');
  
      setTimeout(() => {
        mywindow.document.close();
        mywindow.focus();
    
        mywindow.print();
        mywindow.close();
        
      if (remarksField) {
        remarksField.style.display = "block";
      }
      printPage.style.display = "block";
      buttons.style.display = "block";
      }, 10)
    })
  }
  
  var date;

  try {
    date = format(created, "MMM dd, yyyy ~ h:mma");
  } catch (error) {
    date = format(new Date(created.seconds * 1000), "MMM dd, yyyy ~ h:mma");
  }

  const onBackHandler = () => {
    navigate(-1);
  }

  var doNotTrack;
  if (type === "Letters" || type === "Others") {
    doNotTrack = notTracking.includes(sub);
  } else {
    doNotTrack = notTracking.includes(type);
  }

  var allowComplete;
  if (ind > -1) {
    allowComplete = offices[ind].documents.includes(type) && office === offices[ind].id || oid === offices[ind].id && office === offices[ind].id;
  }

  const onComplete = async(action) => {
    const data = {
      oid: currentUser.data.oid,
      uid: currentUser.uid,
      action: action,
    }
    const status = await createTrackingStatus(id, data);

    if (status) {
      let res;

      if (selected) {
        res = completeAda(id, status.id, selected, data);
      } else {
        res = updateTracking(id, {completed: action === "complete"});
      }

      if (res) {
        setStatuses([{...data, "created": new Date()}, ...statuses]);
        setDoc({...doc, completed: action === "complete"});
      } else {
        await deleteTrackingStatus(id, status.id);
        alert("Failed completing document");
      }

      return;
    }

    alert("Failed creating status");
  }

  const onCancel = async() => {
    if (selected) {
      const remove = await removeAda(selected);

      if (remove !== "success") {
        alert(remove);
        return;
      }
    }

    const date = new Date();
    const cancel = await updateTracking(id, {did: currentUser.uid, date, deleted: true});
    if (cancel) {
      navigate("/")
    }
  }

  const alertDialog = () => {
    var action = completed ? "revert" : "complete";
    var res = window.confirm("You are about to " + action + " document process, would you like to proceed?");
    if (res) {onComplete(action);}
  }

  const alertCancelDialog = () => {
    var res = window.confirm("You are about to cancel document process, would you like to proceed?");
    if (res) {onCancel();}
  }

  const addRemarks = async() => {
    if (submit) {
      return;
    }

    if (!remarks) {
      alert("Add remarks");
      return;
    }

    setSubmit(true);
    const rem = {oid: currentUser.data.oid, uid: currentUser.uid, deleted: false, remarks};
    const res = await createTrackingRemarks(id, rem);

    if (res) {
      setRemarks("");
      rem["created"] = new Date();
      rem["id"] = res.id;
      allRemarks.push(rem);
    } else {
      alert("Submitting remarks failed");
    }
    setSubmit(false);
  }

  const onDeleteHandler = async(id) => {
    var res = window.confirm("You are about to delete a remarks, would you like to proceed?");
    if (res) {removeRemarks(id);}
  }

  const removeRemarks = async(rid) => {
    const res = await updateTrackingRemarks(id, rid, {deleted: true});
    if (res) {
      const rem = allRemarks.filter((rem) => {return rem.id !== rid});
      setAllRemarks(rem);
    } else {
      alert("Deleting remarks failed");
    }
  }

  const onReceive = async(action) => {
    const data = {
      oid: currentUser.data.oid,
      uid: currentUser.uid,
      action: action,
    }
    const status = await createTrackingStatus(id, data);

    if (status) {
      let res;
      let docData;
      if (doNotTrack) {
        docData = {
          receives: arrayUnion(currentUser.data.oid)
        }
      } else {
        docData = {
          offices: arrayUnion(currentUser.data.oid),
          office: currentUser.data.oid,
          user: currentUser.uid
        }
      }

      if (selected) {
        res = receiveAda(id, status.id, selected, data, docData);
      } else {
        res = updateTracking(id, docData);
      }

      if (res) {
        navigate(-1);
      } else {
        await deleteTrackingStatus(id, status.id);
        alert("Failed receiving document");
      }

      return;
    }

    alert("Failed creating status");
  }

  return (
    <div className='tracking'>
      <span className='back' onClick={onBackHandler}><i className="fa fa-arrow-left"></i>Back</span>

      <div className='tracking-container'  id="page">

<div className='tracking-qr'>
  <div id='qr-code'>
    <QRCode id='qr' value={id}/>
  </div>
  <canvas id='canvas' width='300px' height='300px'/>
  <div id='buttons'>
    {!isAdmin && oid === currentUser.data.oid ? <div className='buttons-container'><Button type="button" onClick={onPrintHandler}>Print Slip</Button></div> : ""}
    {!isAdmin && !ada && !completed && !deleted && user !== currentUser.uid ? <div className='buttons-container'><Button type="button" buttonType="primary" onClick={() => onReceive(office === currentUser.data.oid ? "transfer" : "receive")}>Receive</Button></div> : ""}
    {!isAdmin && office === offices[ind].id && !completed && !deleted && currentUser.data.access && currentUser.data.access.includes("PR") ? <div className='buttons-container'><Button type="button" onClick={() => navigate("/tracking/" + id + "/pr")}>{pr ? "Update Purchase Request" : "Add Purchase Request"}</Button></div>  : ""}
    {!isAdmin && !ada && statuses.length > 1 && allowComplete && !deleted ? <div className='buttons-container'><Button type="button" onClick={alertDialog} buttonType="primary">{!completed ? "Complete" : "Revert"} Tracking</Button></div>  : ""}
    {!isAdmin && !ada && oid === offices[ind].id && office === offices[ind].id && !completed && !deleted ? <div className='buttons-container'><Button type="button" onClick={alertCancelDialog}>Cancel Tracking</Button></div>  : ""}
  </div>
</div>

        <div className='tracking-item'>
          <div className='tracking-header'>
            <span className='bordered'>{date}</span>
            <span id='print-page' className='bordered print' onClick={onPrintPageHandler}><i className="fa fa-print"></i></span>
            <h2>{title}</h2>
          </div>
          <p>{sub ? type + " - " + sub : type}</p>
          {pr ? <p>PR: {pr}</p> : ""}
          <p style={{whiteSpace: "pre-wrap", textAlign: "justify"}}>{particulars}</p>

          <div className={`${doNotTrack ? "not-tracking" : ""} tracking-history`}>
            {statuses.map((doc, i) => {
              const officeIndex = offices.map(e => e.id).indexOf(doc.oid); 
              const userIndex = users.map(e => e.id).indexOf(doc.uid); 

              var stat;
              if (doc.action) {
                switch (doc.action) {
                  case "create":
                      stat = "Document Origin";
                        break;
                    case "transfer":
                      stat = "Transferred";
                        break;
                    case "complete":
                      stat = "Completed";
                        break;
                    case "revert":
                      stat = "Reverted";
                        break;
                    case "delete":
                      stat = "Deleted";
                        break;
                    default:
                        if (i > 0) {
                          stat = "Done";
                        } else {
                          if (doc.oid === oid) {
                            stat = "Returned";
                          } else {
                            stat = "Processing";
                          }
                        }
                }
              } else {
                if (oid === doc.oid) {
                  if (i + 1 === statuses.length) {
                    stat = "Document Origin";
                  } else {
                    stat = "Returned";
                    if (statuses.length > i + 1) {
                      const s = statuses[i + 1];
                      if (s.oid === doc.oid) {
                        stat = "Transferred";
                      }
                    }
  
                    if (completed) {
                      stat = "Completed";
                    }
                    
                    if (deleted) {
                      stat = "Cancelled";
                    }
                  }
                } else {
                  if (i > 0) {
                    stat = "Done";
                  } else {
                    stat = "Processing";
  
                    if (completed) {
                      stat = "Completed";
                    }
                  }
                }
              }

              if (doNotTrack && stat !== "Document Origin" && stat !== "Completed" && stat !== "Reverted") {
                stat = "Received";
              }
              
                  return (
                      <StatusItem key={doc.id} created={doc.created} additionalClass={i + 1 === statuses.length ? statuses.length === 1 ? "last" : "last active" : i > 0 || completed ? "active" : ""} status={stat} officeName={doc.oid ? officeIndex > -1 ? offices[officeIndex].name : "Undefined" : "Admin"} name={userIndex > -1 ? users[userIndex].name : "Undefined"}/>
                  )
              })}
          </div>


          <div className='remarks-container'>
          <h3>{allRemarks.length} Remarks</h3>
          {allRemarks.map((doc, i) => {
            const officeIndex = offices.map(e => e.id).indexOf(doc.oid); 
            const userIndex = users.map(e => e.id).indexOf(doc.uid); 

            return (
              <RemarksItem user={currentUser.uid} onDeleteHandler={onDeleteHandler} key={doc.id} doc={doc} officeName={officeIndex > -1 ? offices[officeIndex].name : "Undefined"} name={userIndex > -1 ? users[userIndex].name : "Undefined"}/>
            )
          })}

          {!isAdmin && office === offices[ind].id && !completed && !deleted ?
              <div id='remarks-field'>
                <FormTextArea type="text" rows="3" name="remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} label="Remarks"/>
                <Button type="button" onClick={addRemarks}>Submit Remarks</Button>
              </div>
              : ""
          }
          </div>
        </div>
      </div>

        <div id='print'>
          <div className='print-header'>
            <img src={seal} height='120px' width='120px'/>
            <div className='print-header-content'>
            <p>Republic of the Philippines</p>
            <p>PROVINCIAL GOVERNMENT OF CAMARINES NORTE</p>
            <span>Daet</span>
            </div>
            <img src={pagasa} height='120px' width='120px'/>
          </div>
          
          <p>DOCUMENT TRACKING SYSTEM SLIP</p>

          <table className='slip'>
            <tbody>
              <tr>
                <td>ORIGINATING OFFICE:</td>
                <td>{pos > -1 ? offices[pos].name + " - " + offices[pos].full : "Undefined"}</td>
                <td rowSpan="6"><QRCode id='qr' value={id}/></td>
              </tr>
              
              <tr>
                <td>TRANSACTION:</td>
                <td>{sub ? type + " - " + sub : type}</td>
              </tr>
              
              <tr>
                <td>CONTROL NUMBER:</td>
                <td>{title}</td>
              </tr>
              
              <tr>
                <td>PARTICULARS:</td>
                <td style={{whiteSpace: "pre-wrap"}}>{particulars}</td>
              </tr>
              
              <tr>
                <td>DATE:</td>
                <td>{date}</td>
              </tr>
              
              <tr>
                <td>PROCESSING TIME:</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>

          {type === "Leave" && participants || type === "Travel Order" && participants ?
          <table>
          <tbody>
            {type === "Leave" ? 
            <tr>
              <td>No.</td>
              <td>Employee Name</td>
              <td>No. of WDs applied for/Inclusive Dates</td>
              <td>Remarks</td>
            </tr>
          : 
            <Fragment>
              <tr>
                <td colSpan="3" style={{fontWeight: "bold", textAlign: "center", fontSize: 120 + "%"}}>LIST OF RECOMMENDED ATTENDEES</td>
              </tr>
              <tr>
                <td style={{textAlign: "center"}}>No.</td>
                <td style={{textAlign: "center"}}>Name</td>
                <td style={{textAlign: "center"}}>Position/Designation</td>
              </tr>
            </Fragment>
          }
            {participants && participants.map((participant, index) => {
              const {name, from, to, position} = participant;

              return type === "Leave" ? <tr key={index}>
              <td style={{textAlign: "center"}}>{index + 1}</td>
              <td>{name}</td>
              <td>{format(new Date(from), "MMM dd, yyyy")} - {format(new Date(to), "MMM dd, yyyy")}</td>
              <td></td>
            </tr> : <tr key={index}>
                              <td style={{textAlign: "center"}}>{index + 1}</td>
                              <td>{name}</td>
                              <td>{position}</td>
                            </tr>;
            })}
          </tbody>
        </table> : ""}

          <RoutingSlip type={type} sub={sub} participants={participants}/>
      </div>
    </div>
  )
}

export default QrCode;
