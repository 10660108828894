import './report.scss'
import { useContext, useState, useEffect } from 'react';
import { getReportDocs, getTrackingStatuses } from '../../utils/firebase';
import "react-datepicker/dist/react-datepicker.css";
import { ProductsContext } from '../../context/product-context';
import Button from '../../components/button/button';
import FormSelect from '../../components/form-select/form-select';
import { options } from '../new-tracking/new-tracking';
import report from '../../assets/report.xlsx'
import logs from '../../assets/logs.xlsx'
import ExcelJS from 'exceljs';
import { UsersContext } from '../../context/users-context';
import { format } from 'date-fns';

const defaultFormFields = {
    office: '',
    type: '',
  }

const Report = () => {
    const { users } = useContext(UsersContext);
    const { offices } = useContext(ProductsContext);
    const [officesArray, setOfficesArray] = useState([]);
    const [formFields, setFormFields] = useState(defaultFormFields);
    const {office, type} = formFields;
    const [maxDate, setMaxDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [generate, setGenerate] = useState(false);
    const [trackingLogs, setTrackingLogs] = useState(false);
    const [loader, setLoader] = useState("0%");

    useEffect(() => {
        let off = offices.map((office) => office.full);
        off = off.filter(name => !name.includes("Test Office"));
        setOfficesArray(["All", ...off]);

        const date = new Date();
        const max = date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0');
        setMaxDate(max);
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({...formFields, [name]: value});
    };

    const generateReport = async () => {
        if (generate) {
          return;
        }

        setGenerate(true);
        const now = new Date();
        const pos = offices.map(e => e.full).indexOf(office);
        let oid = office;
        if (pos > -1) {
            oid = offices[pos].id;
        }

        const res = await getReportDocs(oid, type, new Date(fromDate), new Date(toDate));
        let blob = await fetch(report).then(r => r.blob());

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(blob);

        const sheet = workbook.getWorksheet("Sheet1");
        const borderStyles = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };

          for (let i = 0; i < res.length; i++) {
            const {title, type, particulars, created, uid, oid, completed, deleted} = res[i];
            var status = "Processing";
            if (completed) {
                status = "Completed";
            }
            if (deleted) {
                status = "Cancelled";
            }

            var date, time;
            try {
              date = format(created, "MMMM dd, yyyy");
              time = format(created, "h:mma");
            } catch (error) {
              const crea = new Date(created.seconds * 1000);
              date = format(crea, "MMMM dd, yyyy");
              time = format(crea, "h:mma");
            }

            let nam = "Undefined";
            let ful = "Undefined";

            const officeIndex = offices.map(e => e.id).indexOf(oid); 
            const userIndex = users.map(e => e.id).indexOf(uid); 
            if (userIndex > -1) {
              const {name} = users[userIndex];
              if (name) {
                nam = name;
              }
            }
            if (officeIndex > -1) {
              const {full} = offices[officeIndex];
              if (full) {
                ful = full;
              }
            }

            const row = i + 3;
            sheet.insertRow(row, [title, type, particulars, date, time, nam, ful, status], 'o+');
            sheet.getRow(row).eachCell({ includeEmpty: true }, function(cell, colNumber) {
                cell.border = borderStyles;
                cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
              });
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const url = window.URL.createObjectURL(
          new Blob([buffer]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${office.replace(" ", "_")}_${type.replace(" ", "_")}_report_${now.getTime()}.xlsx`,
        );
    
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setGenerate(false);
    };

    const generateLogs = async() => {
      if (trackingLogs) {
        return;
      }

      setTrackingLogs(true);
      const now = new Date();
      const pos = offices.map(e => e.full).indexOf(office);
      let oid = office;
      if (pos > -1) {
          oid = offices[pos].id;
      }

      const res = await getReportDocs(oid, type, new Date(fromDate), new Date(toDate));
      let blob = await fetch(logs).then(r => r.blob());

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(blob);

      const sheet = workbook.getWorksheet("Sheet1");
      
      for (let i = 0; i < res.length; i++) {
        const {title, type, particulars, id} = res[i];
        const values = [title, type, particulars, "-"];
        const statuses = await getTrackingStatuses(id);
        for (let i = 0; i < statuses.length; i++) {
          const {oid, uid, created} = statuses[statuses.length - 1 - i];

          var date;
          try {
            date = format(created, "MMMM dd, yyyy ~ h:mma");
          } catch (error) {
            const crea = new Date(created.seconds * 1000);
            date = format(crea, "MMMM dd, yyyy ~ h:mma");
          }

          let nam = "Undefined";
          let ful = "Undefined";

          const officeIndex = offices.map(e => e.id).indexOf(oid); 
          const userIndex = users.map(e => e.id).indexOf(uid); 
          if (userIndex > -1) {
            const {name} = users[userIndex];
            if (name) {
              nam = name;
            }
          }
          if (officeIndex > -1) {
            const {name} = offices[officeIndex];
            if (name) {
              ful = name;
            }
          }
          values.push(ful);
          values.push(nam);
          values.push(date);
          values.push("-");
        }

        sheet.getColumn(i + 1).width = 65;
        sheet.getColumn(i + 1).values = values;
        sheet.getColumn(i + 1).eachCell({ includeEmpty: true }, function(cell, rowNumber) {
          const isBold = rowNumber === 1 || (rowNumber - 1) % 4 === 0;
          cell.style = {font: {bold: isBold, size: 14}};
          cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
        });

        const percent = Math.round(((i + 1) / res.length) * 100);
        setLoader(percent + "%");
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const url = window.URL.createObjectURL(
        new Blob([buffer]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${office.replace(" ", "_")}_${type.replace(" ", "_")}_tracking_logs_${now.getTime()}.xlsx`,
      );
  
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setTrackingLogs(false);
      setLoader("0%");
    }

    const submitHandler = (event) => {
      event.preventDefault();

      const id = event.nativeEvent.submitter.id;
      if (id === "report") {
        generateReport();
        return;
      }

      if (id === "logs") {
        generateLogs();
        return;
      }
    }

    return (
        <div className='report'>
          <div className='report-container'>
              <h2>Report</h2>
              <p>Choose options</p>
                <form onSubmit={submitHandler}>
                  <FormSelect type="text" style={{margin: 0}} required name="office" value={office} onChange={handleChange} label="Office" options={officesArray}/>
                  <FormSelect type="text" style={{margin: 0}} required name="type" value={type} onChange={handleChange} label="Document Type" options={["All", ...options]}/>
  
                    <div className="group-container">
                        <div className='group'>
                            <input className='date' required type="date" onClick={(e) => {e.target.showPicker()}} name="from" min="2023-05-15" max={maxDate} value={fromDate} onChange={(e) => {
                                    setFromDate(e.target.value);
                                    setToDate("");
                                }}/>
                            <label className="shrink form-select-label">From</label>
                        </div>
                        <div className='group'>
                            <input className='date' required type="date" onClick={(e) => {e.target.showPicker()}} name="to" min={fromDate} max={maxDate} value={toDate} onChange={(e) => {
                                    setToDate(e.target.value);
                                }}/>
                            <label className="shrink form-select-label">To</label>
                        </div>
                    </div>
                  <div className='buttons-container'>
                    {!generate ? <Button type="submit" id="report">Generate Report</Button> :
                    <div className='loading-container'><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}

                    {!trackingLogs ? <Button type="submit" id="logs">Generate Tracking Logs</Button> :
                    <div className='loading-container'><span>{loader}</span><div className="lds-ellipsis" style={{marginRight: 40 + "px"}}><div></div><div></div><div></div><div></div></div></div>}
                  </div>
                </form>
          </div>
        </div>
      )
  }

export default Report;