import './forgot-password.scss'
import { resetPassword } from '../../../utils/firebase';
import { useState } from 'react';
import FormInput from '../../../components/form-input/form-input';
import Button from '../../../components/button/button';

const ForgotPassword = () => {
    // Email and Password login
    const [email, setEmail] = useState("");
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await resetPassword(email);
        if (response === "success") {
            setEmail("");
            alert("Success. Check your inbox and spam.");
        } else {
            alert(response);
        }
    }

    return (
            <div className='forgot-password-container'>
                <h2>Forgot Password</h2>
                <p>Type in your email address, we will send you a link to reset your password.</p>
                <form onSubmit={handleSubmit}>
                    <FormInput type="email" required name="email" value={email} onChange={(e) => setEmail(e.target.value)} label="Email Address"/>

                    <div className='buttons-container'>
                        <Button type="submit">Send Reset Password Link</Button>
                    </div>
                </form>
            </div>
    )
}

export default ForgotPassword;