import './dashboard.scss'
import { useContext, useState, useEffect, Fragment } from 'react';
import { UserContext } from "../../context/user-context";
import { getTrackingDocuments, getCompletedTrackingDocuments } from '../../utils/firebase';
import DocumentItem from '../../components/document-item/document-item';
import "react-datepicker/dist/react-datepicker.css";
import { ProductsContext } from '../../context/product-context';
import FormSelect from '../../components/form-select/form-select';
import { DashboardContext } from '../../context/dashboard-context';
import {useParams, useNavigate} from 'react-router-dom';
import { notTracking } from '../qr-code/qr-code';
import { options } from '../new-tracking/new-tracking';

const Dashboard = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const {category, setCategory, search, setSearch, filter, setFilter, currentDate, setCurrentDate, activeOffice, setActiveOffice} = useContext(DashboardContext);
    const { offices } = useContext(ProductsContext);
    const [trackingDocs, setTrackingDocs = () => []] = useState([]);
    const [docs, setDocs = () => []] = useState([]);
    const [page, setPage] = useState([0, 0, 0, 0]);
    const maxDocs = 20;
    const isAdmin = currentUser.data.type === "admin";
    const oid = isAdmin ? id : currentUser.data.oid;
    const pos = offices.map(e => e.id).indexOf(oid);
    const [office, setOffice] = useState(activeOffice ? activeOffice : offices[pos].name);
    const [maxDate, setMaxDate] = useState("");

    var officesArray = offices.map(e => e.name);

    var categoryIndex;
    switch(category) {
        case "created":
            categoryIndex = 0;
            break
        case "in":
            categoryIndex = 1;
            break
        case "out":
            categoryIndex = 2;
            break;
        default:
            categoryIndex = 3;
    }

    useEffect(() => {
        const date = new Date();
        const max = date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0');
        setMaxDate(max);
    }, []);

    useEffect(() => {
        async function getDocs() {
            const date = currentDate ? new Date(currentDate) : new Date();
            const start = new Date(date.getFullYear(), date.getMonth(), 0);
            const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            start.setHours(23, 59, 59);
            end.setHours(23, 59, 59);

            if (category === "completed") {
                const docs = await getCompletedTrackingDocuments(oid, start, end);
                
                const index = offices.map(e => e.name).indexOf(office);
                const tempOid = offices[index].id;

                const filteredDocs = docs.filter((doc) => {return doc.office === tempOid});
                setTrackingDocs(filteredDocs);
                return;
            }

            const docs = await getTrackingDocuments(oid, category, start, end);
            setTrackingDocs(docs);
        };
        getDocs();
    }, [currentDate, category, office]);

    useEffect(() => {
      var items = [];
        if (filter !== "All") {
          items = trackingDocs.filter((doc) => {return doc.type === filter});
        } else {
          items = trackingDocs;
        }

        if (search !== "") {
          items = items.filter((doc) => {return doc.particulars && doc.particulars.toUpperCase().includes(search.toUpperCase()) || doc.title.toUpperCase().includes(search.toUpperCase()) || doc.pr && doc.pr.toUpperCase().includes(search.toUpperCase())});
        }

        setDocs(items);
    }, [filter, trackingDocs, search, office]);

    const onRefreshHandler = () => {
        const cat = category;
        setCategory("");

        setTimeout(() => {
            setCategory(cat);
            page[categoryIndex] = 0;
            const newPage = [page[0], page[1], page[2], page[3]];
            setPage(newPage);
        }, 100);
    }

    const pages = [];
    for (let i = 0; i < docs.length / maxDocs; i++) {
        pages.push(i);
    }

    
  const onBackHandler = () => {
    navigate(-1);
  }

    return (
        <div className="dashboard">
            {isAdmin ? <span className='back' onClick={onBackHandler}><i className="fa fa-arrow-left"></i>Back</span> : ""}
        <div className="dashboard-container">
            <div className='dashboard-header'>
            <h2>{isAdmin ? offices[pos].full : "Dashboard"}</h2>

            <div className='right-menu'>
              {category === "completed" ? <FormSelect type="text" name="office" value={office} onChange={(e) => {setOffice(e.target.value); setActiveOffice(e.target.value)}} options={officesArray}/> : ""}
            <FormSelect type="text" name="filter" value={filter} onChange={(e) => setFilter(e.target.value)} options={["All", ...options]}/>
           
            <input type="month" onClick={(e) => {e.target.showPicker()}} id="date" name="date" min="2023-05" max={maxDate} value={currentDate} onChange={(e) => {
                page[categoryIndex] = 0;
                setPage([...page]);
                setCurrentDate(e.target.value);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}/>
              <span className='refresh' onClick={onRefreshHandler}>Refresh<i className="fa fa-sync"></i></span>
              </div>
            </div>
            
            <div className='search-container'>
              <div className='search'>
              <input type="text" placeholder="Type here" name="search" value={search} onChange={(e) => {setSearch(e.target.value)}}/>
              </div>
              </div>
              
        <div className="category-container">
          <span className={`${category === "created" ? "active" : ""} nav-link`} onClick={() => {setCategory("created")}}>Created</span>
          <span className={`${category === "in" ? "active" : ""} nav-link`} onClick={() => {setCategory("in")}}>Inside</span>
          <span className={`${category === "out" ? "active" : ""} nav-link`} onClick={() => {setCategory("out")}}>Outside</span>
          <span className={`${category === "completed" ? "active" : ""} nav-link`} onClick={() => {setCategory("completed")}}>Completed</span>
      </div>

      <div className='table-container'>
            <table border="0" cellSpacing="0" cellPadding="0">
                <thead>
                <tr>
                    <th>No.</th>
                    <th>Control No.</th>
                    <th>Document Type</th>
                    <th>Date Created</th>
                    <th>Origin</th>
                    <th>Location</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {docs.map((doc, index) => {
                    if (index >= (page[categoryIndex] * maxDocs) && index < maxDocs + (page[categoryIndex] * maxDocs)) {
                        const o = offices.map(e => e.id).indexOf(doc.oid);
                        const c = offices.map(e => e.id).indexOf(doc.office);
    
                        var doNotTrack;
                        if (doc.type === "Letters") {
                            doNotTrack = notTracking.includes(doc.sub);
                        } else {
                            doNotTrack = notTracking.includes(doc.type);
                        }

                        var cur = "-";
                        if (doNotTrack && doc.receives) {
                            const x = offices.map(e => e.id).indexOf(doc.receives[0]);
                            if (x > -1) {
                                if (doc.receives.length > 1) {
                                    const size = doc.receives.length - 1;
                                    if (size > 1) {
                                        cur = offices[x].name + " & " + size + " others";
                                    } else {
                                        cur = offices[x].name + " & 1 other";
                                    }
                                } else {
                                    cur = offices[x].name;
                                }
                            }
                        }

                        return (
                            <DocumentItem key={index} doc={doc} index={index} office={o > -1 ? offices[o] : null} current={doNotTrack ? cur : c > -1 ? offices[c].name : "Undefined"}/>
                        )
                    }
                })}
                </tbody>
            </table>
        </div>
        </div>

        <div className='page-navigation'>
            {
                pages.map((p) => {
                    const morethan1 = pages.length > 1;
                    const before = page[categoryIndex] - 2;
                    const after = page[categoryIndex] + 2;

                    const button = <span key={p} className={page[categoryIndex] === p ? "active" : ""} onClick={() => {
                        if (p !== page[categoryIndex]) {
                            page[categoryIndex] = p;
                            setPage([...page]);
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                    }}>{p + 1}</span>;

                    const dot = <span>...</span>;

                    if (morethan1) {
                        if (p === 0) {
                            if (page[categoryIndex] > 2) {
                                return <Fragment key={p}>{button}{dot}</Fragment>
                            }
                            return button;
                        } else if (p === pages.length - 1) {
                            if (page[categoryIndex] < pages.length - 3) {
                                return <Fragment key={p}>{dot}{button}</Fragment>
                            }
                            return button;
                        } else if (p > before && p < after) {
                            return button;
                        }
                    }
                })
            }
        </div>
        </div>
    )
}

export default Dashboard;