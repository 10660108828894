import { createContext, useEffect, useState } from "react";

export const DashboardContext = createContext({
    category: "created",
    filter: "All",
    search: "",
    currentDate: "",
    activeOffice: "",
    setCategory: () => {},
    setFilter: () => {},
    setSearch: () => {},
    setCurrentDate: () => {},
    setActiveOffice: () => {},
});

export const DashboardProvider = ({ children }) => {
    const [category, setCategory] = useState("created");
    const [filter, setFilter] = useState("All");
    const [search, setSearch] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [activeOffice, setActiveOffice] = useState("");


    useEffect(() => {
        const date = new Date();
        const max = date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0');
        setCurrentDate(max);
    }, []);

    const value = {category, setCategory, filter, setFilter, search, setSearch, currentDate, setCurrentDate, activeOffice, setActiveOffice};

    return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
} 