import './admin.scss'
import { useContext, useState, useEffect } from 'react';
import { getCount } from '../../utils/firebase';
import "react-datepicker/dist/react-datepicker.css";
import { ProductsContext } from '../../context/product-context';
import OfficeItem from '../../components/office-item/office-item';

const Admin = () => {
    const { offices } = useContext(ProductsContext);
    const [processing, setProcessing] = useState(0);
    const [deleted, setDeleted] = useState(0);
    const [completed, setCompleted] = useState(0);

    useEffect(() => {
        async function get() {
            const pro = await getCount("processing");
            setProcessing(pro);
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const pro = await getCount("completed");
            setCompleted(pro);
        };
        get();
    }, []);

    useEffect(() => {
        async function get() {
            const pro = await getCount("deleted");
            setDeleted(pro);
        };
        get();
    }, []);

    return (
        <div className="admin">
            <div className='stats-container'>
                <h2>Tracking Stats</h2>
                
                <div className='stats-item'>
                    <div className='stats-wrapper'>
                        <h2>{processing}</h2>
                        <p>Processing</p>
                    </div>
                </div>
                
                <div className='stats-item'>
                    <div className='stats-wrapper'>
                        <h2>{completed}</h2>
                        <p>Completed</p>
                    </div>
                </div>
                
                <div className='stats-item'>
                    <div className='stats-wrapper'>
                        <h2>{deleted}</h2>
                        <p>Deleted</p>
                    </div>
                </div>
            </div>
            <div className="office-container">
                {offices.map((office, index) => {
                        return (
                            <OfficeItem key={office.id} office={office}/>
                        )
                    })}
            </div>
        </div>
    )
}

export default Admin;