import './search.scss'
import { UserContext } from "../../context/user-context";
import { useContext, useState, useEffect } from 'react';
import { ProductsContext } from '../../context/product-context';
import { searchTrackingDocuments } from '../../utils/firebase';
import DocumentItem from '../../components/document-item/document-item';
import { notTracking } from '../qr-code/qr-code';

const Search = () => {
    const { currentUser } = useContext(UserContext);
    const { offices } = useContext(ProductsContext);
    const [search, setSearch] = useState("");
    const [docs, setDocs = () => []] = useState([]);

    useEffect(() => {
        async function getDocs() {
            const result = await searchTrackingDocuments(search, currentUser.data.type === "admin" ? "" : currentUser.data.oid);
            setDocs(result);
        };
        getDocs();
    }, [search]);

    return (
<div className="dashboard">
        <div className="dashboard-container">
            <div className='dashboard-header'>
            <h2>Search</h2>
            </div>
        <div className="search-container">
            <div className='search'>
                <input type="text" placeholder="Type here" name="search" value={search} onChange={(e) => {setSearch(e.target.value)}}/>
            </div>
      </div>

      <div className='table-container'>
            <table border="0" cellSpacing="0" cellPadding="0">
                <thead>
                <tr>
                    <th>No.</th>
                    <th>Control No.</th>
                    <th>Document Type</th>
                    <th>Date Created</th>
                    <th>Origin</th>
                    <th>Location</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {docs.map((doc, index) => {
                    const o = offices.map(e => e.id).indexOf(doc.oid);
                    const c = offices.map(e => e.id).indexOf(doc.office);

                    var doNotTrack;
                    if (doc.type === "Letters") {
                        doNotTrack = notTracking.includes(doc.sub);
                    } else {
                        doNotTrack = notTracking.includes(doc.type);
                    }

                    var cur = "-";
                    if (doNotTrack && doc.receives) {
                        const x = offices.map(e => e.id).indexOf(doc.receives[0]);
                        if (x > -1) {
                            if (doc.receives.length > 1) {
                                const size = doc.receives.length - 1;
                                if (size > 1) {
                                    cur = offices[x].name + " & " + size + " others";
                                } else {
                                    cur = offices[x].name + " & 1 other";
                                }
                            } else {
                                cur = offices[x].name;
                            }
                        }
                    }

                    return (
                        <DocumentItem key={doc.id} doc={doc} index={index} office={o > -1 ? offices[o] : null} current={doNotTrack ? cur : c > -1 ? offices[c].name : "Undefined"}/>
                    )
                })}
                </tbody>
            </table>
        </div>
        </div>
        </div>
    )
}

export default Search;