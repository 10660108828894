import './stats.scss'
import { useContext, useState, useEffect } from 'react';
import { getCount, getCreated, getForwarded, getReceived, getRemaining } from '../../utils/firebase';
import "react-datepicker/dist/react-datepicker.css";
import { ProductsContext } from '../../context/product-context';
import OfficeItem from '../../components/office-item/office-item';
import FormSelect from '../../components/form-select/form-select';
import Button from '../../components/button/button';
import {options} from '../new-tracking/new-tracking'

const Stats = () => {
    const { offices } = useContext(ProductsContext);
    const [office, setOffice] = useState("");
    const [filters, setFilters] = useState([]);
    const [maxDate, setMaxDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [forwarded, setForwarded] = useState([]);
    const [received, setReceived] = useState([]);
    const [remaining, setRemaining] = useState([]);

    useEffect(() => {
        const getResponse = async () => {
            const index = offices.map(e => e.name).indexOf(office); 
            const tempForwarded = [];
            const tempReceived = [];
            const tempRemaining = [];

            for (const filter of filters) {
                if (index == -1 || fromDate == "" || toDate == "") {
                    tempForwarded.push('No data');
                    tempReceived.push('No data');
                    tempRemaining.push('No data');
                    continue;
                }

                const fDate = new Date(fromDate);
                const tDate = new Date(toDate);
                fDate.setHours(0, 0, 0);
                tDate.setHours(23, 59, 59);
                const oid = offices[index].id;

                const crea = await getCreated(oid, filter, fDate, tDate);
                const forw = await getForwarded(oid, filter, fDate, tDate);
                tempForwarded.push(forw - crea);
                const rema = await getRemaining(oid, filter, fDate, tDate);
                tempRemaining.push(rema);
                const rece = await getReceived(oid, filter, fDate, tDate);
                tempReceived.push(rece);
            }

            setForwarded(tempForwarded);
            setReceived(tempReceived);
            setRemaining(tempRemaining);
        }
        getResponse();
    }, [toDate, fromDate, office, filters]);

    const onRemoveHandler = (filter) => {
        const index = filters.indexOf(filter);
        if (index > -1) {
            filters.splice(index, 1);
            setFilters([...filters]);
        }
    }

    return (
        <div className="admin-stats">
            <div className='stats-container'>
                <h2>Stats</h2>
                <FormSelect type="text" name="office" value={office} onChange={(e) => setOffice(e.target.value)} options={offices.map((office) => office.name)} defaultValue='Select Office'/>
                <FormSelect type="text" name="filter" value="" onChange={(e) => setFilters([...filters, e.target.value])} options={options.filter((opt) => !filters.includes(opt))} defaultValue='Add Filter'/>
                        <div className='group'>
                            <input className='date' required type="date" onClick={(e) => {e.target.showPicker()}} name="from" min="2023-05-15" max={maxDate} value={fromDate} onChange={(e) => {
                                    setFromDate(e.target.value);
                                    setToDate("");
                                }}/>
                            <label className="shrink form-select-label">From</label>
                        </div>
                        <div className='group'>
                            <input className='date' required type="date" onClick={(e) => {e.target.showPicker()}} name="to" min={fromDate} max={maxDate} value={toDate} onChange={(e) => {
                                    setToDate(e.target.value);
                                }}/>
                            <label className="shrink form-select-label">To</label>
                        </div>
                    </div>
            <div className="filter-container">
                {filters.map((filter, index) => {
                    const forw = forwarded[index];
                    const rece = received[index];
                    const rema = remaining[index];
                        return (
                            <div className='filter' key={filter}>
                                <h3>{filter} <span className='remove' onClick={() => onRemoveHandler(filter)}><i className="fa fa-trash"></i></span></h3>
                                <div className='stat-container'>
                                    <div className='stat-item'>
                                        <h2>{rece}</h2>
                                        Received
                                    </div>

                                    <div className='stat-item'>
                                        <h2>{forw}</h2>
                                        Forwarded
                                    </div>

                                    <div className='stat-item'>
                                        <h2>{rema}</h2>
                                        Remaining
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default Stats;