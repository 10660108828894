import { Routes, Route } from "react-router-dom";
import Navigation from "./routes/navigation/navigation";
import SignInForm from "./routes/authentication/sign-in-form/sign-in-form";
import QrCode from "./routes/qr-code/qr-code";
import NewTracking from "./routes/new-tracking/new-tracking";
import Dashboard from "./routes/dashboard/dashboard";
import { UserContext } from "./context/user-context";
import { useContext } from "react";
import Unauthorized from './routes/unauthorized/unauthorized';
import Search from "./routes/search/search";
import TwoFactor from "./routes/two-factor/two-factor";
import Admin from "./routes/admin/admin";
import ForgotPassword from "./routes/authentication/forgot-password/forgot-password";
import PurchaseRequest from "./routes/purchase-request/purchase-request";
import Report from "./routes/report/report"
import Scan from "./routes/scan/scan";
import ChangePassword from "./routes/authentication/change-password/change-password";
import Stats from "./routes/stats/stats";

const App = () => {
  const { code, currentUser } = useContext(UserContext);

  if (code) {
    return <TwoFactor/>
  }

  return (
    <Routes>
      <Route path="/" element={<Navigation/>}>
        <Route index element={currentUser ? currentUser.data.type === "admin" ? <Admin/> : <Dashboard/> : <SignInForm/>}/>
        <Route path="/change-password" element={currentUser ? <ChangePassword/> : <Unauthorized/>}/>
        <Route path="/forgot-password" element={currentUser ? <Unauthorized/> : <ForgotPassword/>}/>
        <Route path="/search" element={currentUser ? <Search/> : <Unauthorized/>}/>
        <Route path="/new-tracking" element={currentUser ? <NewTracking/> : <Unauthorized/>}/>
        <Route path="/tracking/:id" element={currentUser ? <QrCode/> : <Unauthorized/>}/>
        <Route path="/report" element={currentUser && currentUser.data.type === "admin" ? <Report/> : <Unauthorized/>}/>
        <Route path="/scan" element={currentUser ? <Scan/> : <Unauthorized/>}/>
        <Route path="/stats" element={currentUser ? currentUser.data.type === "admin" ? <Stats/> : <Unauthorized/> : <Unauthorized/>}/>
        <Route path="/office/:id" element={currentUser ? currentUser.data.type === "admin" ? <Dashboard/> : <Unauthorized/> : <Unauthorized/>}/>
        <Route path="/tracking/:id/pr" element={currentUser ? currentUser.data.access && currentUser.data.access.includes("PR") ? <PurchaseRequest/> : <Unauthorized/> : <Unauthorized/>}/>
      </Route>
    </Routes>
  );
}

export default App;
