import './purchase-request.scss'
import { useState, useEffect } from 'react';
import Button from '../../components/button/button';
import {useNavigate, useParams} from 'react-router-dom';
import FormInput from '../../components/form-input/form-input';
import { getTrackingDocument, updateTracking } from '../../utils/firebase';

const defaultDoc = {
  oid: "",
  completed: false,
  created: new Date(),
  type: "",
  sub: "",
  office: "",
  deleted: false,
  title: "",
  particulars: "",
  pr: ""
}

const PurchaseRequest = () => {
    const { id } = useParams();
    const [doc, setDoc] = useState(defaultDoc);
    const navigate = useNavigate();
    const {pr, type, title, sub} = doc;

    useEffect(() => {
      const getResponse = async () => {
        const tracking = await getTrackingDocument(id);
  
        if (tracking) {
          if (!tracking.pr) {
            tracking["pr"] = "";
          }
          setDoc(tracking);
        }
      }
      getResponse();
  }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const res = updateTracking(id, {pr});
        if (res) {
          navigate(-1);
        }
    }

    return (
      <div className='purchase-request'>
      <span className='back' onClick={() => {navigate(-1)}}><i className="fa fa-arrow-left"></i>Back</span>
        <div className='purchase-request-container'>
            <h2>{title}</h2>
            <p>{sub ? type + " - " + sub : type}</p>
            <form onSubmit={handleSubmit}>
                <FormInput type="text" required value={pr} onChange={(e) => setDoc({...doc, pr: e.target.value})} label="Purchase Request"/>

                <div className='buttons-container'>
                    <Button type="submit">Save PR</Button>
                </div>
            </form>
        </div>
      </div>
    )
}

export default PurchaseRequest;