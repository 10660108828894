import './change-password.scss'
import { updateUserPassword } from '../../../utils/firebase';
import { useState } from 'react';
import FormInput from '../../../components/form-input/form-input';
import Button from '../../../components/button/button';

const defaultFormFields = {
    password: '',
    confirmPassword: '',
}

const ChangePassword = () => {
    const [show, setShow] = useState(false);
    const [formFields, setFormFields] = useState(defaultFormFields);
    const {password, confirmPassword} = formFields;

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({...formFields, [name]: value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert("Password do not match");
        } else {
            const res = await updateUserPassword(password);

            if (res === "success") {
                alert("Password updated successfully");
                resetFormFields();
            } else {
                if (res === "Firebase: Error (auth/requires-recent-login).") {
                    alert("For safety and security, you must first re-login before you can change your password.")
                    return;
                }
                alert(res);
            }
        }
    }

    return (
            <div className='forgot-password-container' id='forgot-password'>
                <h2>Change Password</h2>
                <p>Type in a new password.</p>
                <form onSubmit={handleSubmit}>
                    <FormInput type={show ? "text": "password"} required name="password" value={password} onChange={handleChange} label="New Password"/>
                    <FormInput type={show ? "text": "password"} required name="confirmPassword" value={confirmPassword} onChange={handleChange} label="Confirm Password"/>
                    <div className='group'>
                        <input type="checkbox" checked={show} name="checkbox" onClick={() => setShow(!show)}/>
                        <label onClick={() => setShow(!show)}>Show Password</label>
                    </div>
                    <div className='buttons-container'>
                        <Button type="submit">Update Password</Button>
                    </div>
                </form>
            </div>
    )
}

export default ChangePassword;