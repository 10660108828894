import { Fragment, useContext } from "react";
import { Outlet, Link } from "react-router-dom";
import './navigation.scss'
import seal from '../../assets/seal.png'
import { UserContext } from "../../context/user-context";
import { signOutUser } from "../../utils/firebase";
import {useNavigate} from 'react-router-dom';
import { useState } from "react";
import { ProductsContext } from "../../context/product-context";

const Navigation = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const { offices } = useContext(ProductsContext);
  const pos = offices.map(e => e.id).indexOf(currentUser ? currentUser.data.oid : "");

  const signOutHandler = () => {
    signOutUser();
    navigate('/');
  }

  const isAdmin = currentUser && currentUser.data.type === "admin";
  if (currentUser) {
    if (currentUser.data.deactivated) {
      alert("Your account has been deactivated.");
      signOutHandler();
    }
  }

  return (
    <Fragment>
      <div className="navigation-container">
        <div className="navigation">
          <Link className="logo-container" to='/'>
            <img alt="logo" className='logo' src={seal}/>
            <h1>PGCN Document Tracking System</h1>
          </Link>
          <div className="nav-links-container">
            {currentUser ? isAdmin ? 
             <Fragment>
              <Link className="nav-link" to='/stats'>Stats</Link>
              <Link className="nav-link" to='/report'>Report</Link>
             </Fragment> :
             <Fragment>
              <Link className="nav-link" to='/scan'>Scan</Link>
              <Link className="nav-link" to='/new-tracking'>New Tracking</Link>
            </Fragment> : ""}
            {currentUser ? <Link className="nav-link" to='/search'>Search</Link> : ""}
            {currentUser ?
            <span className="nav-link" onClick={() => setToggle(!toggle)}><i className="fa fa-bars"></i>
              <ul>
                <div className="nav-head">
                  <div className="avatar">{currentUser.data.name.charAt(0)}</div>
                  <h3>{currentUser.data.name}</h3>
                  <span>{pos > -1 ? offices[pos].full : isAdmin ? "Admininistrator" : ""}</span>
                </div>
                <li><a onClick={() => navigate("/change-password")}>Change Password</a></li>
                <li><a onClick={signOutHandler}>Sign Out</a></li>
              </ul>
            </span>: ""}
          </div>
        </div>
      </div>

      <Outlet/>
    </Fragment>
  )
}

export default Navigation;
