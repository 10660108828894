import { initializeApp } from 'firebase/app'
import { getAuth, signInWithRedirect, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail, updatePassword } from 'firebase/auth'
import { getFirestore, doc, getDoc, setDoc, addDoc, collection, query, where, getDocs, orderBy, startAt, endAt, updateDoc, limit, deleteField, serverTimestamp, deleteDoc, increment, getCountFromServer, writeBatch } from 'firebase/firestore'
 
const firebaseConfig = {
  apiKey: "AIzaSyAC2Ul_IViAi8bg7Niz-wYk3lJH2QoWc84",
  authDomain: "document-tracking-system-3cf01.firebaseapp.com",
  projectId: "document-tracking-system-3cf01",
  storageBucket: "document-tracking-system-3cf01.appspot.com",
  messagingSenderId: "428497933109",
  appId: "1:428497933109:web:2a55a0c1dc9989ad53727c"
};
  
  const app = initializeApp(firebaseConfig);

  const googleProvider = new GoogleAuthProvider();
  googleProvider.setCustomParameters({
    prompt: "select_account"
  });

  export const auth = getAuth();
  export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);
  export const signInWithGoogleRedirect = () => signInWithRedirect(auth, googleProvider);
  
  export const db = getFirestore();

  export const createUserDocument = async (userAuth, additionalInformation = {}) => {
    if (!userAuth) return;
    
    const userDocRef = doc(db, 'users', userAuth.uid);
    const userSnapshot = await getDoc(userDocRef);
    var data = userSnapshot.data();

    if (!userSnapshot.exists()) {
        const { displayName, email, photoURL } = userAuth;
        data =  {
          displayName,
          photoURL,
          email,
          "created": serverTimestamp(),
          ...additionalInformation
       };

        try {
            await setDoc(userDocRef, data)
        } catch (error) {
            console.log("error", error.message);
        }
    }

    userAuth["data"] = data;

    return userDocRef;
  }

  export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return await createUserWithEmailAndPassword(auth, email, password);
  }

  export const signInUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return await signInWithEmailAndPassword(auth, email, password);
  }

  export const signOutUser = async () => await signOut(auth);

  export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);

  export const createTrackingDocument = async (additionalInformation = {}) => {
    const trackingRef = collection(db, "trackings");
    var trackingDoc = null;

    try {
      trackingDoc = await addDoc(trackingRef, {
          ...additionalInformation, "created": serverTimestamp(), "version": 2
      })
    } catch (error) {
      console.log("error", error.message);
    }

    return trackingDoc;
  }
  
  export const createTrackingStatus = async (id, additionalInformation = {}) => {
    const statusRef = collection(db, "trackings/" + id + "/statuses");
    var statusDoc = null;

    try {
      statusDoc = await addDoc(statusRef, {
          ...additionalInformation, "created": serverTimestamp()
      })
    } catch (error) {
      console.log("error", error.message);
    }

    return statusDoc;
  }

  export const createSample = async() => {
    const ref = collection(db, "sample");
    var success = true;
    
    try {
      await addDoc(ref, {"server": serverTimestamp(), "date": new Date()});
    } catch (error) {
      success = false;
    }

    return success;
  }

  export const getSample = async (id) => {
    const docRef = doc(db, 'sample', id);
    var trackingDoc = null;

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        trackingDoc = docSnap.data();
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("error", error.message);
    }

    return trackingDoc;
  }
  
  export const createTrackingRemarks = async (id, additionalInformation = {}) => {
    const statusRef = collection(db, "trackings/" + id + "/remarks");
    var statusDoc = null;

    try {
      statusDoc = await addDoc(statusRef, {
          ...additionalInformation, "created": serverTimestamp()
      })
    } catch (error) {
      console.log("error", error.message);
    }

    return statusDoc;
  }

  export const deleteTrackingDocument = async (id) => {
    const ref = doc(db, "trackings", id);

    await deleteDoc(ref);
  }

  export const deleteTrackingStatus = async (id, sid) => {
    const ref = doc(db, "trackings/" + id + "/statuses", sid);

    await deleteDoc(ref);
  }

  export const incrementOfficeNum = async (id) => {
    const ref = doc(db, "offices", id);
    await updateDoc(ref, {num: increment(1)});
  }

  export const getTrackingDocuments = async (oid, category, start, end) => {
    const trackingRef = collection(db, "trackings");
    const trackingDocs = [];
    var q;
    if (category === "in") {
      q = query(trackingRef, where("completed", "==", false), where("deleted", "==", false), where("office", "==", oid), orderBy("created"), endAt(end), startAt(start));
    } else if (category === "out") {
      q = query(trackingRef, where("completed", "==", false), where("deleted", "==", false), where("offices", "array-contains", oid), orderBy("created"), endAt(end), startAt(start));
    } else {
      q = query(trackingRef, where("deleted", "==", false), where("oid", "==", oid), orderBy("created"), endAt(end), startAt(start));
    }

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          trackingDocs.push(data);
      });
    
      trackingDocs.sort(function(a, b){
        return b.created - a.created;
      });
    } catch (error) {
      console.log("error", error.message);
    }

    return trackingDocs;
  }

  export const getReportDocs = async(oid, type, startDate, endDate) => {
    const trackingRef = collection(db, "trackings");
    const trackingDocs = [];
    const types = [];
    types.push(type);
    if (type === "Payroll/Other Employee Benefits") {
      types.push("Payroll");
    }
    if (type === "Purchase Request/Service Request") {
      types.push("Purchase Request");
    }

    const start = startDate.setHours(0, 0, 0);
    const end = endDate.setHours(23, 59, 59);

    var q;
    if (oid === "All") {
      q = query(trackingRef, orderBy("created"), startAt(new Date(start)), endAt(new Date(end)));

      if (type !== "All") {
        q = query(trackingRef, where("type", "in", types), orderBy("created"), startAt(new Date(start)), endAt(new Date(end)));
      }
    } else {
      q = query(trackingRef, where("oid", "==", oid), orderBy("created"), startAt(new Date(start)), endAt(new Date(end)));

      if (type !== "All") {
        q = query(trackingRef, where("oid", "==", oid), where("type", "in", types), orderBy("created"), startAt(new Date(start)), endAt(new Date(end)));
      }
    }

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          trackingDocs.push(data);
      });
    } catch (error) {
      console.log("error", error.message);
    }

    return trackingDocs;
  }

  export const getTrackingDocumentsByType = async (oid, type) => {
    const trackingRef = collection(db, "trackings");
    const trackingDocs = [];
    const types = [];
    types.push(type);
    if (type === "Payroll/Other Employee Benefits") {
      types.push("Payroll");
    }
    if (type === "Purchase Request/Service Request") {
      types.push("Purchase Request");
    }
    const q = query(trackingRef, where("completed", "==", false), where("deleted", "==", false), where("office", "==", oid), where("type", "in", types), orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          trackingDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return trackingDocs;
  }

  export const getCompletedTrackingDocuments = async (oid, start, end) => {
    const trackingRef = collection(db, "trackings");
    const trackingDocs = [];
    const q = query(trackingRef, where("completed", "==", true), where("deleted", "==", false), where("oid", "==", oid), where("offices", "array-contains", oid), orderBy("created"), endAt(end), startAt(start));
    const q2 = query(trackingRef, where("deleted", "==", false), where("oid", "==", oid), where("receives", "array-contains", oid), orderBy("created"), endAt(end), startAt(start));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          trackingDocs.push(data);
      });

      const docs2 = await getDocs(q2);

      docs2.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          trackingDocs.push(data);
      });

      trackingDocs.sort(function(a, b){
        return b.created - a.created;
      });
    } catch (error) {
      console.log("error", error.message);
    }
    
    return trackingDocs;
  }

  export const getReceivedDocuments = async (oid, date) => {
    const trackingRef = collection(db, "trackings");
    const trackingDocs = [];
    const start = date.setHours(0, 0, 0);
    const end = date.setHours(23, 59, 59);
    var q = query(trackingRef, where("deleted", "==", false), where("receives", "array-contains", oid), orderBy("created"), startAt(new Date(start)), endAt(new Date(end)));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          trackingDocs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return trackingDocs;
  }
  
  export const getTrackingDocument = async (id) => {
    const docRef = doc(db, 'trackings', id);
    var trackingDoc = null;

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        trackingDoc = docSnap.data();
        trackingDoc["id"] = docSnap.id;
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("error", error.message);
    }

    return trackingDoc;
  }

  export const downloadFile = (fileName, url) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      };
      xhr.open('GET', url);
      xhr.send();
    } catch (error) {
      return "error: " + error.message;
    }
  }
  
  export const searchTrackingDocuments = async (title, oid) => {
    const docRef = collection(db, 'trackings');
    const q = query(docRef, where("title", "==", title), where("deleted", "==", false), orderBy("created", "desc"));
    const trackingDocs = [];

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          if (data["offices"].includes(oid) || data["receives"] && data["receives"].includes(oid) || oid === "") {
            trackingDocs.push(data);
          }
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return trackingDocs;
  }

  export const getTrackingNum = async (oid) => {
    const docRef = collection(db, 'trackings');
    const q = query(docRef, where("oid", "==", oid), orderBy("created", "desc"), limit(1));
    var num = 0;

    try {
      const docs = await getDocs(q);
      if (docs.size === 1) {
        num = docs.docs[0].data()["num"];
      }
      num++;
    } catch (error) {
      console.log("error", error.message);
    }

    return num;
  }

  export const getTrackingStatuses = async (id) => {
    const trackingRef = collection(db, "trackings/" + id + "/statuses");
    const trackingDocs = [];
    const q = query(trackingRef, orderBy("created", "desc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
        const data = doc.data();
        data["id"] = doc.id;
        trackingDocs.push(data);
    });
    } catch (error) {
      console.log("error", error.message);
    }

    return trackingDocs;
  }

  export const getTrackingRemarks = async (id) => {
    const trackingRef = collection(db, "trackings/" + id + "/remarks");
    const trackingDocs = [];
    const q = query(trackingRef, where("deleted", "==", false), orderBy("created", "asc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
        const data = doc.data();
        data["id"] = doc.id;
        trackingDocs.push(data);
    });
    } catch (error) {
      console.log("error", error.message);
    }

    return trackingDocs;
  }

  export const updateTrackingRemarks = async (id, rid, additionalInformation = {}) => {
    const docRef = doc(db, "trackings/" + id + "/remarks", rid);
    var success;

    try {
      await updateDoc(docRef, {...additionalInformation});
      success = true;
    } catch (error) {
      console.log("error", error.message);
    }

    return success;
  }
  
  export const getOffice = async (id) => {
    const docRef = doc(db, 'offices', id);
    var officeDoc = null;

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        officeDoc = docSnap.data();
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("error", error.message);
    }

    return officeDoc;
  }

  export const updateUserPassword = async(password) => {
    var response = "success";
    try {
      await updatePassword(auth.currentUser, password);
    } catch (error) {
      response = error.message;
    }
    return response;
  }

  export const resetPassword = async (email) => {
    var response = "success";
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      response = error.message;
    }

    return response;
  }
  
  export const getUser = async (id) => {
    const docRef = doc(db, 'users', id);
    var officeDoc = null;

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        officeDoc = docSnap.data();
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("error", error.message);
    }

    return officeDoc;
  }

  export const getOffices = async () => {
    const docRef = collection(db, 'offices');
    const offices = [];
    const q = query(docRef, orderBy("name", "asc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
        const data = doc.data();
        data["id"] = doc.id;
        offices.push(data);
    });
    } catch (error) {
      console.log("error", error.message);
    }

    return offices;
  }

  export const getUsers = async () => {
    const docRef = collection(db, 'users');
    const offices = [];
    const q = query(docRef, orderBy("name", "asc"));

    try {
      const docs = await getDocs(q);

      docs.forEach((doc) => {
        const data = doc.data();
        data["id"] = doc.id;
        offices.push(data);
    });
    } catch (error) {
      console.log("error", error.message);
    }

    return offices;
  }

  export const authenticateUser = async (id) => {
    const docRef = doc(db, "users", id);
    var res;

    try {
      await updateDoc(docRef, {code: deleteField()});
      res = "success";
    } catch (error) {
      res = "error: " + error.message;
    }
    return res;
  }

  export const updateTracking = async (id, additionalInformation = {}) => {
    const docRef = doc(db, "trackings", id);
    var success;

    try {
      await updateDoc(docRef, {...additionalInformation});
      success = true;
    } catch (error) {
      console.log("error", error.message);
    }

    return success;
  }

  export const getCount = async(status) => {
    const col = collection(db, "trackings");
    var q = query(col, where("deleted", "==", false), where("completed", "==", false));
    if (status === "completed") {
      q = query(col, where("completed", "==", true));
    } else if (status === "deleted") {
      q = query(col, where("deleted", "==", true));
    }

    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  export const getCreated = async(officeId, type, start, end) => {
    const col = collection(db, "trackings");
    var q = query(col, where("deleted", "==", false), where("oid", "==", officeId), where("type", "==", type), orderBy("created"), endAt(new Date(end)), startAt(new Date(start)));

    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  export const getForwarded = async(officeId, type, start, end) => {
    const col = collection(db, "trackings");
    var q = query(col, where("deleted", "==", false), where("offices", "array-contains", officeId), where("type", "==", type), orderBy("created"), endAt(new Date(end)), startAt(new Date(start)));

    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  export const getRemaining = async(officeId, type, start, end) => {
    console.log(officeId, type, start, end);
    const col = collection(db, "trackings");
    var q = query(col, where("deleted", "==", false), where("office", "==", officeId), where("type", "==", type), orderBy("created"), startAt(new Date(start)), endAt(new Date(end)));

    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  export const getReceived = async(officeId, type, start, end) => {
    const col = collection(db, "trackings");
    var q = query(col, where("deleted", "==", false), where("receives", "array-contains", officeId), where("type", "==", type), orderBy("created"), endAt(new Date(end)), startAt(new Date(start)));

    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  export const getCompletedDocs = async(offices, setMax, setData) => {
    const trackingRef = collection(db, "trackings");
    const q = query(trackingRef, where("completed", "==", true), where("deleted", "==", false), orderBy("created"), limit(1));
    var max = 0;

    try {
      const docs = await getDocs(q);

      docs.forEach(async(doc) => {
          const status = await getTrackingStatuses(doc.id);

          for (var i = 1; status.length > i; i++) {
            const created = new Date(1970, 0, 1);
            created.setSeconds(status[i].created.seconds);

            if (status.length > i + 1) {
              const pos = offices.map(e => e.id).indexOf(status[i].oid);
              const cre = new Date(1970, 0, 1);
              cre.setSeconds(status[i + 1].created.seconds);
              const secs = Math. abs(created - cre) / 1000;

              const seconds = offices[pos]["seconds"] ? offices[pos]["seconds"] + secs : secs;
              offices[pos]["seconds"] = seconds;
              
              const transactions = offices[pos]["transactions"] ? offices[pos]["transactions"] + 1 : 1;
              offices[pos]["transactions"] = transactions;

              if (seconds > max) {
                max = seconds;
                setMax(max);
              }
              setData([...offices]);
            }
          }
      });

    } catch (error) {
      console.log("error", error.message);
    }
  }

  export const createAda = async (ada, list) => {
    const batch = writeBatch(db);

    list.forEach((item) => {
      const sfRef = doc(db, "trackings", item);
      batch.update(sfRef, {ada});
    });

    var response = "success";
    try {
      await batch.commit();
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const removeAda = async (list) => {
    const batch = writeBatch(db);

    list.forEach((item) => {
      const sfRef = doc(db, "trackings", item);
      batch.update(sfRef, {ada : deleteField()});
    });

    var response = "success";
    try {
      await batch.commit();
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const completeAda = async (id, sid, list, data) => {
    const batch = writeBatch(db);

    batch.update(doc(db, "trackings", id), {completed: data.action === "complete"});

    list.forEach((item) => {
      const docRef = doc(db, "trackings", item);
      batch.update(docRef, {completed: data.action === "complete"});
      
      const staRef = doc(db, "trackings/" + item + "/statuses", sid);
      batch.set(staRef, {...data, "created": serverTimestamp()});
    });

    var success;
    try {
      await batch.commit();
      success = true;
    } catch (error) {
      console.log(error.message);
    }

    return success;
  }

  export const receiveAda = async (id, sid, list, data, docData) => {
    const batch = writeBatch(db);

    batch.update(doc(db, "trackings", id), docData);

    list.forEach((item) => {
      const staRef = doc(db, "trackings/" + item + "/statuses", sid);
      batch.set(staRef, {...data, "created": serverTimestamp()});
    });

    var success;
    try {
      await batch.commit();
      success = true;
    } catch (error) {
      console.log(error.message);
    }

    return success;
  }