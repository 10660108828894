import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/user-context';
import { ProductsProvider } from './context/product-context';
import { UsersProvider } from './context/users-context';
import { DashboardProvider } from './context/dashboard-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <UsersProvider>
          <ProductsProvider>
            <DashboardProvider>
              <App />
            </DashboardProvider>
          </ProductsProvider>
        </UsersProvider>
      </UserProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
