import './scan.scss'
import useScanDetection from 'use-scan-detection';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { createTrackingStatus, deleteTrackingStatus, getTrackingDocument, receiveAda, updateTracking } from '../../utils/firebase';
import { UserContext } from "../../context/user-context";
import Button from '../../components/button/button';
import { ProductsContext } from '../../context/product-context';
import { notTracking } from '../qr-code/qr-code';
import { arrayUnion } from 'firebase/firestore';

const Scan = () => {
  const navigate = useNavigate();
  const [multiple, setMultiple] = useState(false);
  const [docs, setDocs = () => []] = useState([]);
  const [doc, setDoc] = useState("");
  const { currentUser } = useContext(UserContext);
  const { offices } = useContext(ProductsContext);
  const [receive, setReceive] = useState(null);

  useEffect(() => {
    const qr = document.getElementById('qr-code');
    qr.focus();
  }, []);

  useEffect(() => {
    const receiveDoc = async () => {
      const {office, sub, type, selected} = receive;

      var doNotTrack;
      if (type === "Letters" || type === "Others") {
        doNotTrack = notTracking.includes(sub);
      } else {
        doNotTrack = notTracking.includes(type);
      }

      const data = {
        oid: currentUser.data.oid,
        uid: currentUser.uid,
        action: office === currentUser.data.oid ? "transfer" : "receive",
      }
      const status = await createTrackingStatus(receive.id, data);

      if (status) {
        let res;
        let docData;
        if (doNotTrack) {
          docData = {
            receives: arrayUnion(currentUser.data.oid)
          }
        } else {
          docData = {
            offices: arrayUnion(currentUser.data.oid),
            office: currentUser.data.oid,
            user: currentUser.uid
          }
        }

        if (selected) {
          res = receiveAda(receive.id, status.id, selected, data, docData);
        } else {
          res = updateTracking(receive.id, docData);
        }

        if (res) {
          const index = docs.indexOf(receive);

          if (index > -1) {
            docs.splice(index, 1);
            const newDocs = [...docs];
            setDocs(newDocs);
            if (newDocs.length > 0) {
              setReceive(newDocs[0]);
              return;
            } else {
              setReceive(null);
              alert("Multiple documents receive complete!");
            }
          } else {
            setReceive(null);
          }
        } else {
          await deleteTrackingStatus(receive.id, status.id);
          alert("Failed receiving document");
          setReceive(-1);
        }
        
        return;
      }

      setReceive(null);
      alert("Failed creating status");
    }

    if (receive !== null) {
      receiveDoc();
    }
  }, [receive]);

  useEffect(() => {
    const getResponse = async () => {
      const tracking = await getTrackingDocument(doc);

      if (tracking) {
        const {user, ada, deleted, completed} = tracking;
        setDoc("");

        if (deleted) {
          alert("Document has been deleted");
          return;
        }
        if (completed) {
          alert("Document is already completed");
          return;
        }
        if (user === currentUser.uid) {
          alert("Document is already scanned");
          return;
        }
        if (ada) {
          alert("Document has been included to an ADA/CA, scan it instead");
          return;
        }
        setDocs([...docs, tracking]);
      }
    }
    if (doc) {
      getResponse();
    }
}, [doc]);

  useScanDetection({
    onComplete: (code) => { 
      const id = code.replace(/Shift/g, '');
      if (multiple) {
        const ind = docs.map(e => e.id).indexOf(id);
        if (ind === -1) {
          setDoc(id);
        } else {
          alert("Document is already added");
        }
        return;
      }
      navigate('/tracking/' + id); 
    }
  });

  const onClickHandler = async() => {
    setReceive(docs[0]);
  }

  const onRemoveHandler = (index) => {
    docs.splice(index, 1);
    setDocs([...docs]);
  }

  const onBackHandler = () => {
    navigate(-1);
  }

  return (
    <div className='qr-code tracking'>
      <span className='back' onClick={onBackHandler}><i className="fa fa-arrow-left"></i>Back</span>

      <div className='right'>
            Multiple Documents
          <label className="switch">
          <input type="checkbox" onClick={(e) => {
            if (multiple) {
              setMultiple(false);
              setDocs([]);
            } else {
              setMultiple(true);
            }
          }}/>
          <span className="slider round"></span>
        </label>
          </div>
      <div className='tracking-container'>
        <div className='tracking-qr'>
          <div id='qr-code' tabIndex="0">
            <QRCode id='qr' value="" />
          </div>
          <canvas id='canvas' width='300px' height='300px'/>

          <h2>Start scanning...</h2>
        </div>

        {docs.length > 0 ? 
          <div className='selectable-table-container'>
            <table border="0" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                    <th>No.</th>
                    <th>Control No.</th>
                    <th>Document Type</th>
                    <th>Origin</th>
                    <th>Action</th>
                </tr>
              </thead>
                <tbody>
                {docs.map((doc, index) => {
                  const pos = offices.map(e => e.id).indexOf(doc.oid);
                  return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{doc.title}</td>
                    <td>{doc.type}</td>
                    <td>{pos > -1 ? offices[pos].name : "-"}</td>
                    <td><span className='remove' onClick={() => onRemoveHandler(index)}><i className="fa fa-trash"></i></span></td>
                  </tr>
                  )
                })}
              </tbody>
            </table>
            <div className='buttons-container'><Button type="button" buttonType="primary" onClick={onClickHandler}>Receive</Button></div>
          </div>
            : ""} 
      </div>
    </div>
  );
}

export default Scan;