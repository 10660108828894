import './new-tracking.scss'
import { useState, useContext, useEffect, Fragment } from 'react';
import Button from '../../components/button/button';
import FormSelect from '../../components/form-select/form-select';
import { createAda, createTrackingDocument, createTrackingStatus, db, deleteTrackingDocument, getTrackingDocumentsByType, incrementOfficeNum } from '../../utils/firebase';
import {useNavigate} from 'react-router-dom';
import { UserContext } from "../../context/user-context";
import { ProductsContext } from '../../context/product-context';
import { doc, onSnapshot } from 'firebase/firestore';
import FormTextArea from '../../components/form-textarea/form-textarea';
import FormInput from '../../components/form-input/form-input'

const defaultFormFields = {
  type: '',
  sub: '',
  particulars: '',
  participants: [{
    name: "",
    from: "mm/dd/yyyy",
    to: "mm/dd/yyyy",
    position: ""
  }],
  selected: []
}

const NewTracking = () => {
    const { currentUser } = useContext(UserContext);
    const { offices } = useContext(ProductsContext);
    const pos = offices.map(e => e.id).indexOf(currentUser.data.oid);
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { type, sub, particulars, participants, selected } = formFields;
    const [submit, setSubmit] = useState(false);
    const [num, setNum] = useState(0);
    const [trackingDocs, setTrackingDocs = () => []] = useState([]);
    const [filter, setFilter] = useState("Payroll/Other Employee Benefits");

    useEffect(() => {
      const unsubscribe = onSnapshot(doc(db, "offices", currentUser.data.oid), (doc) => {
        setNum(doc.data().num);
      });

      return unsubscribe;
    }, []);

    useEffect(() => {
      async function getDocs() {
        const docs = await getTrackingDocumentsByType(currentUser.data.oid, filter);
        setTrackingDocs(docs.filter((doc) => {return !doc.ada}));
      };
      
      if (type === "Authority to Debit Account/Debit Memo" || type === "Cash Advance") {
        getDocs();
      }
    }, [type, filter]);

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({...formFields, [name]: value});
    }

    const handleParticipant = (event, index) => {
      const {name, value} = event.target;
      participants[index][name] = value;
      setFormFields({...formFields});
    }

    const removeParticipant = (index) => {
      participants.splice(index, 1);
      setFormFields({...formFields});
    }

    const addParticipant = () => {
      const item = {
        name: "",
        from: "mm/dd/yyyy",
        to: "mm/dd/yyyy",
        position: ""
      };
      participants.push(item);
      
      setFormFields({...formFields});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (participants.length === 0) {
          alert("Add participants");
          return;
        }

        if (type === "Authority to Debit Account/Debit Memo" && selected.length === 0 || type === "Cash Advance" && selected.length === 0) {
          alert("Select tracking documents");
          return;
        }
        
        if (submit) {
          return;
        }
        setSubmit(true);

        if (type === "Liquidation Report" || type === "Others" || type === "Travel Order" || type === "Bills" || type === "Letters" || type === "Payroll/Other Employee Benefits" || type === "COA" || type === "Budget" || type === "Payment to Suppliers" || type === "Purchase Request/Service Request"
        || type === "Internal Audit Service" || type === "Provincial General Services Office" || type === "Leave" || type === "Program of Works" || type === "Honorarium" || type === "Travel Expense Voucher") {
          // do nothing
        } else {
          formFields["sub"] = null;
        }

        if (type === "Leave" || type === "Travel Order") {
          // do nothing
        } else {
          formFields["participants"] = null;
        }

        if (type === "Authority to Debit Account/Debit Memo" || type === "Cash Advance") {
          // do nothing
        } else {
          formFields["selected"] = null;
        }

        formFields["user"] = currentUser.uid;
        formFields["uid"] = currentUser.uid;
        formFields["oid"] = currentUser.data.oid;
        formFields["deleted"] = false;
        formFields["completed"] = false;
        formFields["office"] = currentUser.data.oid;
        formFields["offices"] = [currentUser.data.oid];
        formFields["receives"] = [];
        formFields["num"] = num;
        formFields["title"] = offices[pos].name + "-" + new Date().getFullYear().toString().substring(2, 4) + "-" + num.toString().padStart(5, "0");

        try {
            const trackingDoc = await createTrackingDocument(formFields);
            if (trackingDoc) {
              const statusData = {
                  oid: currentUser.data.oid,
                  uid: currentUser.uid
              }

              const statusDoc = await createTrackingStatus(trackingDoc.id, statusData);
              formFields["id"] = trackingDoc.id;

              if (statusDoc) {
                await incrementOfficeNum(currentUser.data.oid);

                if (type === "Authority to Debit Account/Debit Memo" || type === "Cash Advance") {
                  const ada = await createAda(trackingDoc.id, selected);

                  if (ada !== "success") {
                    // TODO create fallback when failed
                    alert(ada);
                  }
                }

                navigate('/tracking/' + trackingDoc.id);
              } else {
                await deleteTrackingDocument(trackingDoc.id);

                setSubmit(false);
                alert("Creating status document failed")
              }
            } else {
              setSubmit(false);
              alert("Creating tracking document failed")
            }
            resetFormFields();
        } catch (error) {
            setSubmit(false);
            alert("Tracking failed: " + error.message)
        }
    }

    const onClickHandler = (id) => {
      if (selected.includes(id)) {
        const sel = selected.filter((sel) => {return sel !== id});
        var par = "";
        sel.forEach((item) => {
          const pos = trackingDocs.map(e => e.id).indexOf(item);
          par = par + trackingDocs[pos].title + "\n";
        })
        setFormFields({...formFields, selected: sel, particulars: par});
        return;
      }

      const pos = trackingDocs.map(e => e.id).indexOf(id);
      const p = particulars + trackingDocs[pos].title + "\n";
      setFormFields({...formFields, selected: [...selected, id], particulars: p});
    }

    if (currentUser.data.access) {
      const ada = "Authority to Debit Account/Debit Memo";
      if (currentUser.data.access.includes("ADA") && !options.includes(ada)) {
        options.push(ada);
        options.sort();
        options.splice(options.indexOf("Others"), 1);
        options.push("Others");
      }
      const ca = "Cash Advance";
      if (currentUser.data.access.includes("CA") && !options.includes(ca)) {
        options.push(ca);
        options.sort();
        options.splice(options.indexOf("Others"), 1);
        options.push("Others");
      }
    }

    return (
      <div className='new-tracking'>
      <span className='back' onClick={() => {navigate(-1)}}><i className="fa fa-arrow-left"></i>Back</span>
        <div className='new-tracking-container'>
            <h2>New Document/Tracking</h2>
            <p>Fill in the following required fields</p>
            <form onSubmit={handleSubmit}>
                <FormSelect type="text" required name="type" value={type} onChange={handleChange} label="Category" options={options}/>

                {type === "Liquidation Report" || type === "Others" || type === "Travel Order" || type === "Bills" || type === "Letters" || type === "Payroll/Other Employee Benefits" || type === "COA" || type === "Budget" || type === "Payment to Suppliers" || type === "Purchase Request/Service Request"
                || type === "Internal Audit Service" || type === "Provincial General Services Office"  || type === "Leave" || type === "Program of Works" || type === "Honorarium" || type === "Travel Expense Voucher" ?
                
                <FormSelect type="text" required name="sub" value={sub} onChange={handleChange} label="Type" options={
                  type === "Travel Order" ? travel : type === "Bills" ? bills : type === "Letters" ? letters : type === "Payroll/Other Employee Benefits" ? payroll : type === "COA" ? coa :
                  type === "Budget" ? budget : type === "Internal Audit Service" ? audit : type === "Provincial General Services Office" ? services : 
                  type === "Leave" ? leave : type === "Program of Works" ? program : type === "Honorarium" ? honorarium : type === "Payment to Suppliers" ? payment :
                  type === "Purchase Request/Service Request" ? request : type === "Travel Expense Voucher" ? voucher : type === "Others" ? others : type === "Liquidation Report" ? liquidation : []
                  }/> : ""}
                
                {type === "Authority to Debit Account/Debit Memo" || type === "Cash Advance" ?
                <Fragment>
                  <FormSelect type="text" required name="filter" value={filter} onChange={(e) => setFilter(e.target.value)} label="Filter" options={options}/>
                  <div className='selectable-table-container'>
                    <table border="0" cellSpacing="0" cellPadding="0">
                    <thead>
                      <tr>
                          <th>Control No.</th>
                          <th>Document Type</th>
                          <th>Origin</th>
                      </tr>
                      </thead>
                      <tbody>
                      {trackingDocs.map((doc, index) => {
                        const pos = offices.map(e => e.id).indexOf(doc.oid);
                        return (
                        <tr key={index} onClick={() => {onClickHandler(doc.id)}} className={selected.includes(doc.id) ? "active" : ""}>
                          <td>{doc.title}</td>
                          <td>{doc.type}</td>
                          <td>{pos > -1 ? offices[pos].name : "-"}</td>
                        </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  </div>
                </Fragment>
                : <FormTextArea type="text" rows="3" name="particulars" value={particulars} onChange={handleChange} label="Particulars"/>}

                {participants && participants.map((participant, index) => {
                    const {name, from, to, position} = participant;

                    const leave = <div key={index} className='leave'>
                                    <FormInput type="text" required name="name" value={name} label="Name" onChange={(e) => {handleParticipant(e, index)}}/>
                                    <FormInput type="date" required name="from" value={from} label="From" onChange={(e) => {handleParticipant(e, index)}}/>
                                    <FormInput type="date" required name="to" value={to} label="To" onChange={(e) => {handleParticipant(e, index)}}/>
                                    <span onClick={() => {removeParticipant(index)}} className='remove'><i className="fa fa-times"></i></span>
                                  </div>

                    const travel = <div key={index} className='travel'>
                                    <FormInput type="text" required name="name" value={name} label="Name" onChange={(e) => {handleParticipant(e, index)}}/>
                                    <FormInput type="text" required name="position" value={position} label="Position" onChange={(e) => {handleParticipant(e, index)}}/>
                                    <span onClick={() => {removeParticipant(index)}} className='remove'><i className="fa fa-times"></i></span>
                                  </div>
                    return type === "Leave" ? leave : type === "Travel Order" ? travel : ""
                  })}

                <div className='buttons-container'>
                    <Button type="submit">Save and Generate QR</Button>
                  {type === "Leave" || type === "Travel Order" ? <Button buttonType="primary" type="button" onClick={addParticipant}>Add Participant</Button> : ""}
                </div>
            </form>
        </div>
      </div>
    )
}

const budget = [
  "Statement of Appropriation, Allotment, Utilization and Balances"
]

const bills = [
  "Electricity", "Water", "Telephone", "Internet", "Taxes and Other Fees", "Others"
]

const coa = [
  "Audit Observation Memorandum", "Notice of Suspension", "Notice of Disallowance", "Notice of Settlement of Suspension/Disallowance/Charge"
]

const services = [
  "Report of Physical Count of Property, Plant and Equipment", "Job Order Request"
]

const audit = [
  "Internal Audit Report", "Audit Notification Memorandum", "Internal Audit Observation Memorandum", "Commitment Plan"
]

const letters = [
  "Memorandum/Notices/Order/Executive Order", "Letter Request", "Endorsement/ Referrals", "Personal Letter",
  "MOA/MOU", "Transmittal Letter", "Compliance to Memo/ Order/ Request"
]

const payroll = [
  "Cash Advance", "ATM", "Check"
]

const program = [
  "Below 1M", "5M and Above"
]

const leave = [
  "Sick Leave", "Vacation Leave", "Special Privilege Leave", "Half-Day Leave", "Others"
]

const honorarium = [
  "Cash Advance", "Check"
]

const payment = [
  "Fuel/Oil", "Others"
]

const request = [
  "Competitive Bidding", "Alternative Method of Procurement"
]

const voucher = [
  "Cash Advance", "Reimbursement"
]

const travel = [
  "Trainings/Seminars", "Regular Travel"
]

const others = [
  "For Processing", "For Approval", "Copy Furnished"
]

const liquidation = [
  "Travel Expense Voucher", "Others"
]

export const options =    [
  "Accomplishment Reports",
  "Annual Budget",
  "Annual Investment Plan",
  "APP/PPMP",
  "Audit Observation Memorandum",
  "BAC Resolution",
  "Bills",
  "Clearances",
  "CNPH Procurement",
  "Commitment Plan",
  "Compliance to Offices",
  "Contract/Plantilla/NOSA",
  "Daily Time Record",
  "Driver's Trip Ticket/Requisition and Issuance Slip",
  "Duty Schedule",
  "Endorsement Letter/ Contract/ Plantilla",
  "Financial Assistance",
  "Financial Statements",
  "Honorarium",
  "Income Tax Return",
  "Internal Audit Observation Memorandum",
  "Internal Audit Report",
  "IPCR/DPCR/Performance Monitoring Sheet",
  "Job/Service Request",
  "Learning Action Plan",
  "Leave",
  "Letters",
  "Liquidation Report",
  "Locator Slip",
  "Notice of Disallowance",
  "Notice of Salary Adjustment/Notice of Salary Increment",
  "Notice of Settlement of Suspension/Disallowance/Charge",
  "Notice of Suspension",
  "Payment to Suppliers",
  "Payment for MAIP Program Claims",
  "Payroll/Other Employee Benefits",
  "Permits",
  "Personal Data Sheet",
  "Petty Cash",
  "Program of Works",
  "Project Design/Proposal",
  "Property Acknowledgement Report/Inventory Custodian Slip",
  "Purchase Request/Service Request",
  "Report of Accountability of Accountable Forms",
  "Report of Collections and Deposits",
  "Report of Physical Count of Property, Plant and Equipment",
  "Request for Contract Review",
  "Request for Inspection",
  "Requisition and Issuance Slip for Fuel",
  "SP Resolution",
  "Statement of Appropriation, Allotment, Utilization and Balances",
  "Statement of Assets, Liabilities and Net Worth",
  "Summary of Leave Balances",
  "Travel Expense Voucher",
  "Travel Order",
  "Others"
]

export default NewTracking;