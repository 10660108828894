import './document-item.scss'
import Button from '../button/button';
import {useNavigate} from 'react-router-dom';

const OfficeItem = ({office}) => {
    const navigate = useNavigate();

    const { full, name } = office;

    const onClickHandler = () => {
        navigate('/office/' + office.id);
    }

    return (
        <div className='office-item'>
            <div className='office-wrapper'>
                <h2>{name}</h2>
                <p>{full}</p>

                <Button type="submit" onClick={onClickHandler}>View</Button>
            </div>
        </div>
    )
}

export default OfficeItem;